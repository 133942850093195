import { Nav } from "./components/Nav/nav.component";
import { Specializations } from "./components/Specializations/specializations.component";
import { About } from "./components/About/about.component";
import { Pros } from "./components/Pros/pros.component";
import { Contacts } from "./components/Contacts/contacts.component";
import { Projects } from "./components/Projects/projects.components";
import { LCS } from "./components/LCS/lcs.component";
import { Footer } from "./components/Footer/footer.component";
import "./App.styles.scss";

function App() {
  return (
    <div className="App">
      <Nav />
      <About />
      <Specializations />
      {/* <LCS /> */}
      <Pros />
      <Projects />
      <Contacts />
      <Footer />
    </div>
  );
}

export default App;
