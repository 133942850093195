import Logo from "../../assets/logo letters/footer logo.svg";
import "./footer.styles.scss";
export function Footer() {
  return (
    <div className="Footer section" id="Footer">
      <div className="Footer__main-info">
        <div className="Footer__logo-container">
          <img src={Logo} alt="logo" className="Footer__main-info__logo" />
        </div>

        <div className="Footer__business">
          <h3>ООО «Агат»</h3>
          <p>ИНН 7814597893</p>
          <p>КПП 781401001</p>
        </div>

        <div className="Footer__adress">
          <h3>Юр. адрес</h3>
          <p>Санкт-Петербург, пр. Королёва, д. 31</p>
        </div>

        <div>
          <ul className="Footer__phone-numbers">
            <h3> Контакты</h3>
            <li className="Footer__phone-numbers__item">
              <a href="tel:8(812)6456781">+7(812)645-67-81</a>
            </li>
            <li className="Footer__phone-numbers__item">
              <a href="tel:8(812)6456782">+7(812)645-67-82</a>
            </li>
            <li className="Footer__phone-numbers__item">
              <a href="tel:8(812)6456783">+7(812)645-67-83</a>
            </li>
            <li style={{ marginTop: "1vw" }}>
              <a href="mailto:info@agat-climate.ru">info@agat-climate.ru</a>
            </li>
            <li>
              <a href="mailto:98471894@mail.ru">98471894@mail.ru </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
