import "./card.styles.scss";


function LCSCard(props) {
  return (
    <div className="Card" id={props.id}>
      <div className="Card__inner">
        <div className="Card__front">
          <h1>{props.title}</h1>
        </div>
        <div className="Card__back">
          <p className="Card__back__info" href="#LCS">{props.about}
          <a className="Card__back__link" target="_blank" href="https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FCpSVQ6xcDoGUJK38U8bbJ2yT6FZzA%2FfKDt%2B64fHMGuD5vecIosJVMn5Iido5S%2B8dq%2FJ6bpmRyOJonT3VoXnDag%3D%3D%3A%2F%D0%9F%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BA%D0%B0%D0%BD%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85%20%D0%BE%D1%87%D0%B8%D1%81%D1%82%D0%BD%D1%8B%D1%85%20%D1%81%D0%BE%D0%BE%D1%80%D1%83%D0%B6%D0%B5%D0%BD%D0%B8%D0%B9%20%D0%95%D0%B2%D1%80%D0%BE%D0%BB%D0%BE%D1%81.pdf&name=%D0%9F%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE%20%D0%BA%D0%B0%D0%BD%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85%20%D0%BE%D1%87%D0%B8%D1%81%D1%82%D0%BD%D1%8B%D1%85%20%D1%81%D0%BE%D0%BE%D1%80%D1%83%D0%B6%D0%B5%D0%BD%D0%B8%D0%B9%20%D0%95%D0%B2%D1%80%D0%BE%D0%BB%D0%BE%D1%81.pdf&nosw=1">Узнать больше о Eurolos →</a></p>
          
          <div className="Card__back__overlay"></div>
        </div>
      </div>
    </div>
  );
}

export default LCSCard;
