import "./specializations.styles.scss";
import Card from "./card.component";
import LCSCard from "./lcs-card.component";

export function Specializations() {
  return (
    <div className="Specializations section" id="Specializations">
      <h2 className="Specializations__title">
        Мы проектируем, строим, обслуживаем, производим
      </h2>
      {/* <h3 className="Specializations__info">
        На сегодняшний день в сферу компетенций ООО «Агат» входят:
      </h3> */}
      <div className="circle" id="circle1"></div>

      <div className="Specializations__list">
        <Card
          title="Вентиляция"
          about="Разрабатываем и воплощаем инженерные системы, действующие по экономичному методу рекуперации энергии, с применением тепловых насосов или без них, по классической схеме. Вы ставите задачу — мы предлагаем лучшее решение."
          id="ventilation"
        />
        <Card
          title="Кондиционирование"
          about="Поставляем и монтируем климатическое оборудование только от надежных производителей. Длительная гарантия на продукцию и развернутая сеть сервисных центров, где всегда есть нужные запчасти, - главные критерии, по которым мы выбираем бренды. "
          id="conditioning"
        />
        <Card
          title="Отопление"
          about="Наши инженеры проектируют и реализуют оптимальную схему отопительной системы, ориентированную на потребности конкретного клиента. Кроме того, мы готовы разработать техническое задание для ваших подрядчиков. "
          id="heating"
        />
        <Card
          title="Теплоснабжение"
          about="Проектируем и устанавливаем котельные в частных домах по принципу: система должна быть функциональна и эстетична. Наши котельные не только согревают здание, но и радуют глаз. "
          id="temperature-control"
        />
        <Card
          title="Водопровод"
          about="При оборудовании систем водоснабжения применяем материалы нового поколения или проверенные временем: нержавеющую сталь, металлопластик, полипропилен и другие. Выбор за вами."
          id="plumbing"
        />
        <Card
          title="Канализация"
          about="Оснащаем дома надежными и простыми системами, которые не требуют частого обслуживания и не доставляют проблем."
          id="sewerage"
        />

        <Card
          title="Промышленный холод"
          about="Строим «под ключ» индустриальные холодильные камеры."
          id="refrigeration-systems"
        />
        <Card
          title="Оборудование для промышленного холода "
          about="Изготавливаем холодильные сплит-системы разной мощности при температурном диапазоне от -30°С до +10С°."
          id="cold-room-manufacturing"
        />
        <Card
          title="Автоматика"
          about="Производим шкафы АСУ с применением контроллеров Segnetics Pixel."
          id="automation"
        />
        <Card
          title="Узлы обвязки"
          about="Выпускаем готовые узлы обвязки водяных калориферов, фанкойлов."
          id="assemblies"
        />
        <Card
          title="Сервисное обслуживание инженерных систем"
          about="ООО «АГАТ» выполняет гарантийное и постгарантийное обслуживание всех инженерных систем, которые производят и устанавливают наши специалисты."
          id="maintenance"
        />

        <Card
          title="Алмазное бурение"
          about="Работаем по технологии сухого бурения, а значит, без грязи и мусора. Есть смысл заказать услугу даже после чистовой отделки помещения - она не пострадает. "
          id="drilling"
        />
        <LCSCard
          title="Локальные очистные сооружения"
          about="Станции Евролос - оптимальное сочетание качества, надёжности и цены."
          id="lcs"
        />

        <h3 className="Specializations__list__item" id="text-card">
          Мы идём в ногу со временем и всегда используем в работе новые
          технологии, находим пути решения даже для самых нестандартных задач.
        </h3>
      </div>
    </div>
  );
}
