import "./projects.styles.scss";
import Carousel from "../Carousel/carousel.component";

export function Projects() {
  return (
    <div className="Projects section" id="Projects">
      <h2 className="Projects__title">Нам доверяют</h2>
      <Carousel />
    </div>
  );
}
