import "./card.styles.scss";


function Card(props) {
  return (
    <div className="Card" id={props.id}>
      <div className="Card__inner">
        <div className="Card__front">
          <h1>{props.title}</h1>
        </div>
        <div className="Card__back">
          <p className="Card__back__info">{props.about}</p>
          <div className="Card__back__overlay"></div>
        </div>
      </div>
    </div>
  );
}

export default Card;
