import "./carousel.styles.scss";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { useEffect } from "react";

const Carousel = () => {
  useEffect(() => {
    const swiper = new Swiper(
      ".swiper",
      {
        speed: 1000,
        direction: "horizontal",
        loop: false,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          enabled: true,
          delay: 5000,
          disableOnInteraction: true,
        },
        touchEventsTarget: "wrapper",
      },
      []
    );
  });
  return (
    <div className="swiper">
      {/* <!-- Additional required wrapper --> */}
      <div className="swiper-wrapper">
      <div className="swiper-slide">
          <img
            src={require("../../assets/slider/peter.jpg")}
            alt="Петербургский метрополитен"
          />
          <p className="swiper-slide-description">
          Петербургский метрополитен
          </p>
        </div>
        <div className="swiper-slide">
          <img
            src={require("../../assets/slider/amira.jpg")}
            alt="Производственное предприятие «Мегаполис»"
          />
          <p className="swiper-slide-description">
            Производственное предприятие «Мегаполис»
          </p>
        </div>

        <div className="swiper-slide">
          <img
            src={require("../../assets/slider/mts_bank.jpg")}
            alt="МТС Банк"
          />
          <p className="swiper-slide-description">
            МТС Банк
          </p>
        </div>

        <div className="swiper-slide">
          <img
            src={require("../../assets/slider/university.jpg")}
            alt="Алфёровский университет"
          />
          <p className="swiper-slide-description">
          Алфёровский университет
          </p>
        </div>
        

        <div className="swiper-slide">
          <img
            src={require("../../assets/slider/atlantic_city.jpg")}
            alt="Петербургский метрополитен"
          />
          <p className="swiper-slide-description">
          Торговый бизнес-центр «Atlantic City»
          </p>
        </div>

        {/* <!-- Slides --> */}
        {/* <div className="swiper-slide">
          <img
            src="https://eskaliburspb.ru/thumb/2/X8fqJr7DGw1siyIdyU2JLg/920r/d/5348024590800393_5ad6.jpg"
            alt="Эскалибур"
          />
          <p className="swiper-slide-description">Ресторан Эскалибур, Санкт-Петербург, ул.Сикейроса, д.1</p>
        </div>
         <div className="swiper-slide">
          <img src={require("../../assets/slider/gruna-lund-2.jpg")} alt="d" />
          <p className="swiper-slide-description">ЖК «GrunaLund» компании NCC(к.1.1, к.1.2), г.Всеволожск</p>
        </div>

        <div className="swiper-slide">
          <img src={require("../../assets/slider/calzedonia.jpg")} alt="d" />
          <p className="swiper-slide-description">Сеть магазинов «Calzedonia», г.Санкт-Петербург</p>
        </div>

        <div className="swiper-slide">
          <img src={require("../../assets/slider/nike.jpg")} alt="d" />
          <p className="swiper-slide-description">Сеть магазинов спортивной одежды Nike, г.Санкт-Петербург, Москва</p>
        </div>

        <div className="swiper-slide">
          <img src={require("../../assets/slider/baltic-pearl.jpg")} alt="d" />
          <p className="swiper-slide-description">«Балтийская Жемчужина» (квартал 38.1, 38.2, 39.1, 39.2, 39.3), г.Санкт-Петербург</p>
        </div>
        <div className="swiper-slide">
          <img src={require("../../assets/slider/bmw.jpg")} alt="d" />
          <p className="swiper-slide-description"> Аксель-Моторс BMW, г.Санкт-Петербург, Шкиперский проток, д.21</p>
        </div> */}
      </div>
      <div className="swiper-pagination"></div>
      {/* <!-- If we need navigation buttons --> */}
      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
      {/* <!-- If we need pagination --> */}
    </div>
  );
};
export default Carousel;
