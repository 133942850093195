import "./nav.styles.scss";
import ReactLogo from "../../assets/logo letters/logo1.svg";

export function Nav() {
  return (
    <header className="Nav">
      <div className="desktop">
        <div className="Nav__logo">
          <img alt="logo" src={ReactLogo} />
        </div>

        <div className="Nav__container">
          <ul className="Nav__items">
            <li className="Nav__items__item">
              <a href="#About">О нас</a>
            </li>
            <li className="Nav__items__item">
              <a href="#Specializations">Специализации</a>
            </li>
            {/* <li className="Nav__items__item">
              <a href="#LCS">ЛОС</a>
            </li> */}
            <li className="Nav__items__item">
              <a href="#Pros">Наши преимущества</a>
            </li>
            <li className="Nav__items__item">
              <a href="#Projects">Наши партнеры</a>
            </li>
            <li className="Nav__items__item">
              <a href="#Contacts">Контакты</a>
            </li>
          </ul>

          <div className="Nav__phone-numbers">
            <a href="tel:8(812)6456781" className="Nav__phone-numbers__item">
              +7(812)645-67-81
            </a>

            <a href="tel:8(812)6456782" className="Nav__phone-numbers__item">
              +7(812)645-67-82
            </a>

            <a href="tel:8(812)6456783" className="Nav__phone-numbers__item">
              +7(812)645-67-83
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
