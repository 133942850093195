import "./pros.styles.scss";

export function Pros() {
  return (
    <div className="Pros section" id="Pros">
      <div>
        <h2 className="Pros__title">АГАТ - это...</h2>
        <ul className="Pros__list">
          <li className="Pros__list__item">
            <b className="Pros__info__text__wrapper__title">Климат</b>
            <p className="Pros__list__item__text">
              Благоприятный климат как результат работ, так и философия
              взаимоотношений.
            </p>
          </li>
          <li className="Pros__list__item">
            <b className="Pros__info__text__wrapper__title">Качество</b>
            <p className="Pros__list__item__text">
              Обеспечиваем высокое качество производимых работ.
            </p>
          </li>
          <li className="Pros__list__item">
            <b className="Pros__info__text__wrapper__title">Контроль</b>
            <p className="Pros__list__item__text">
              Контролируются все этапы производства работ.
            </p>
          </li>
        </ul>
      </div>

      <div className="Pros__info">
        <h3>А также...</h3>
        <div className="Pros__info__text">
          <ul className="Pros__info__text__wrapper">
            <li id="li1" className="el">
              <b>Индивидуальные решения</b>
              <p>
                Мы - те специалисты, которые помогут вам найти оптимальные пути
                решения реализации инженерных задач.
              </p>
            </li>
            <li id="li2" className="el">
              <b>
                Комплексный профессиональный подход к работе на всех этапах
                реализации проектов
              </b>
              <p>
                Мы готовы разработать или оптимизировать уже имеющееся проектное
                решение; обеспечить комплексную поставку оборудования и
                материалов; выполнить монтажные и пуско-наладочные работы;
                обеспечить гарантийное и пост-гарантийное обслуживание
                инженерных систем.
              </p>
            </li>
            <li id="li3" className="el">
              <b>Прозрачность</b>
              <p>
                Добросовестно исполняем, гарантируем качество оказываемых услуг
                и поставляемых материалов. Мы всегда на связи со своими
                партнёрами.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
