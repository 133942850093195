import "./contacts.styles.scss";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
export function Contacts() {

  const [isDisabled, setIsDisabled] = useState(true);

  const form = useRef({});
  const captcha = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    try {
      if (isDisabled === false) {
        emailjs
          .sendForm(
            "service_05o2qto",
            "template_mjm05ca",
            form.current,
            // params,
            "IKdd93id3GlkTLUeL"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
              return false;
            }
          );
        setIsDisabled(true);
        alert("Заявка успешно отправлена!");
      } else alert("captcha is not ready");
    } catch (error) {
      alert("Возникла ошибка. Оставьте заявку, отправив письмо на info@agat-climate.ru")
      return false;
    }
  };

  const onCaptchaChage = () => {
    setIsDisabled(false);
  };

  const onCaptchaExpired = () => {
    setIsDisabled(true);
  };

  return (
    <div className="Contacts section" id="Contacts">
      <div className="Contacts__container">
        <h2 className="Contacts__title">Свяжитесь с нами!</h2>
        <form
          className="Contacts__container__form"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="Contacts__container__form__inputs">
            <div className="Contacts__container__form__inputs__input-container">
              <p>Ваше имя:</p>
              <input
                type="text"
                placeholder="Ваше имя..."
                name="name"
                required
                // onChange={(e) => {
                //   setFormData({ ...formData, name: e.target.value });
                // }}
              />
            </div>

            <div className="Contacts__container__form__inputs__input-container">
              <p>Адрес почты для связи:</p>
              <input
                type="email"
                placeholder="example@mail.com"
                name="email"
                required
                // onChange={(e) => {
                //   setFormData({ ...formData, email: e.target.value });
                // }}
              />
            </div>
          </div>
          <div
            className="Contacts__container__form__inputs__input-container"
            required
          >
            <p>Сообщение:</p>
            <textarea
              className="Contacts__container__form__message"
              placeholder="Чем мы можем Вам помочь?"
              name="message"
              required
              // onChange={(e) => {
              //   setFormData({ ...formData, message: e.target.value });
              // }}
            />
          </div>
          <ReCAPTCHA
            sitekey="6LclejQnAAAAADELyLJP3CINRfmvON4CTZJZfbtz"
            ref={captcha}
            onChange={onCaptchaChage}
            onExpired={onCaptchaExpired}
            size="compact"
          />
          <input
            type="submit"
            value="Отправить"
            className="Contacts__container__form__submit"
            disabled={isDisabled}
            id="submit"
          />
        </form>
      </div>
    </div>
  );
}
