import "./about.styles.scss";

export function About() {
  return (
    <div className="About section" id="About">
      <img src={require("../../assets/deco.png")} alt="deco" />
      <div className="About__info">
        <div className="About__info__text">
          <h1>
            В команде ООО «АГАТ» - профи высшего класса. Работаем на территории
            Санкт-Петербурга и Ленинградской области более восьми лет.
          </h1>
          <h2>
            Новостройка или дом на стадии реконструкции? В том и другом случае
            компания «Агат» отвечает за его благоустройство и комфорт!
          </h2>
          {/* <h3>Мы работаем по принципу трёх "К":</h3> */}
        </div>

        <div className="About__info__slogan">
          <div id="slogan1" className="slogan">
            <img src={require("../../assets/logo letters/1.png")} alt="" />
          </div>
          <div id="slogan2" className="slogan">
            <img src={require("../../assets/logo letters/2.png")} alt="" />
          </div>
          <div id="slogan3" className="slogan">
            <img src={require("../../assets/logo letters/3.png")} alt="" />
          </div>
        </div>
        <div className="About__info__mobile">
          <img
            src={require("../../assets/logo letters/logo ascending.png")}
            alt="slogan"
          />
        </div>
      </div>
    </div>
  );
}
